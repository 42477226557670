/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    pre: "pre",
    code: "code",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "basic",
    style: {
      position: "relative"
    }
  }, "Basic", React.createElement(_components.a, {
    href: "#basic",
    "aria-label": "basic permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Progress Circle is used to display percentage. It can be used to represent the data or the completion of a task or an operation."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing={2}>\n\t<ProgressCircle style={{maxWidth: 120}} progress={33}>33%</ProgressCircle>\n\t<ProgressCircle style={{maxWidth: 120}} progress={66}>60%</ProgressCircle>\n\t<ProgressCircle style={{maxWidth: 120}} progress={100}>100%</ProgressCircle>\n</Stack>\n")), "\n", React.createElement(_components.h1, {
    id: "colors",
    style: {
      position: "relative"
    }
  }, "Colors", React.createElement(_components.a, {
    href: "#colors",
    "aria-label": "colors permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "We can add colored variants to draw attention to particular statuses or for use in visualizations."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing={2}>\n\t<ProgressCircle style={{maxWidth: 120}} progress={20} color='red'>20%</ProgressCircle>\n\t<ProgressCircle style={{maxWidth: 120}} progress={40} color='yellow'>40%</ProgressCircle>\n\t<ProgressCircle style={{maxWidth: 120}} progress={60}>60%</ProgressCircle>\n\t<ProgressCircle style={{maxWidth: 120}} progress={80} color='blue'>80%</ProgressCircle>\n\t<ProgressCircle style={{maxWidth: 120}} progress={100} color='green'>100%</ProgressCircle>\n</Stack>\n")), "\n", React.createElement(_components.h1, {
    id: "size",
    style: {
      position: "relative"
    }
  }, "Size", React.createElement(_components.a, {
    href: "#size",
    "aria-label": "size permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Progress Circle does not have specified sizes, it fills the available width the parent container allows."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing={3}>\n\t<Card>\n\t\t<Card.Section style={{height: '100%', boxSizing: 'border-box'}} className='d-f justify-content-center'>\n\t\t\t\t<ProgressCircle style={{minWidth: 80}} progress={33}>33%</ProgressCircle>\n\t\t</Card.Section>\n\t</Card>\n\t<Card>\n\t\t<Card.Section style={{height: '100%', boxSizing: 'border-box'}} className='d-f justify-content-center'>\n\t\t\t\t<ProgressCircle style={{minWidth: 80}} progress={66}>66%</ProgressCircle>\n\t\t</Card.Section>\n\t</Card>\n\t<Card>\n\t\t<Card.Section style={{height: '100%', boxSizing: 'border-box'}} className='d-f justify-content-center'>\n\t\t\t\t<ProgressCircle style={{minWidth: 80}} progress={100}>100%</ProgressCircle>\n\t\t</Card.Section>\n\t</Card>\n</Stack>\n")), "\n", React.createElement(_components.h1, {
    id: "examples",
    style: {
      position: "relative"
    }
  }, "Examples", React.createElement(_components.a, {
    href: "#examples",
    "aria-label": "examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<div style={{width: 150}}>\n\t<ProgressCircle progress={33}>\n\t\t<Stack alignItems='center' justifyContent='center' direction='column'>\n\t\t\t33%\n\t\t\t<Eyebrow>in-progress</Eyebrow>\n\t\t</Stack>\n\t</ProgressCircle>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Stack spacing={3}>\n\t<Card style={{width: 400}}>\n\t\t<Stack spacing={3} alignItems='center'>\n\t\t\t<Stack.Item style={{maxWidth: 70}}>\n\t\t\t<ProgressCircle progress={50} color='green'>50%</ProgressCircle>\n\t\t\t</Stack.Item>\n\t\t\t<Stack.Item>\n\t\t\t\t<Headline>Job Status</Headline>\n\t\t\t\t<Eyebrow>5 Done | 5 Not Done</Eyebrow>\n\t\t\t</Stack.Item>\n\t\t</Stack>\n\t\t<hr style={{border: '1px solid #eeeeee'}} className='m-y-3' />\n\t\t<Stack spacing={3} direction='column'>\n\t\t\t<Card style={{\n\t\t\t\tbackgroundColor: '#E3FCFF',\n\t\t\t\tborder: '1px solid #0CA5C0',\n\t\t\t}}>\n\t\t\t\t<Headline className='c-cyan-500 m-0 align-items-center d-f'>\n\t\t\t\t\t<Icon name='schedule' size='24px' className='m-r-1'/> Scheduled\n\t\t\t\t</Headline>\n\t\t\t</Card>\n\t\t\t<ToggleSwitch\n\t\t\t\tchecked\n\t\t\t\tlabel='Job Notification'\n\t\t\t/>\n\t\t</Stack>\n\t</Card>\n</Stack>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "importing",
    style: {
      position: "relative"
    }
  }, "Importing", React.createElement(_components.a, {
    href: "#importing",
    "aria-label": "importing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "code: true\n---\nimport { ProgressCircle } from '@servicetitan/design-system';\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
